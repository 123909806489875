<div style="text-align: center; padding: 25px 15px; position: relative">
  <div class="back-to-invoice">
    <button mat-flat-button color="primary" (click)="backToInvoice()" *ngIf="from_invoice_checker">
      <mat-icon style="width: 14px; padding-right: 10px">keyboard_backspace</mat-icon> Back to Invoice
    </button>
  </div>

  <h2>{{ 'UPDATE_CARD.TITLE' | translate }}</h2>
  <p style="color: red; font-weight: 500; padding: 10px; font-size: 12px; padding-bottom: 0px">
    {{ 'UPDATE_CARD.DESCRIPTION' | translate }}.
  </p>
  <p style="font-weight: 500; padding: 10px; font-size: 12px; padding-top: 5px">
    {{ 'UPDATE_CARD.CONTINUE' | translate }}.
    <a style="cursor: pointer" (click)="reloadForm()">{{ 'UPDATE_CARD.CLICK_HERE' | translate }}</a
    >.
  </p>
  <div style="width: 95%; margin: auto" *ngIf="hostedPageUrl">
    <iframe [src]="hostedPageUrl" title="Zoho Card Form" style="width: 100%; height: 650px; border: none"></iframe>
  </div>
  <div class="error" *ngIf="!hostedPageUrl">
    <div class="img-wrapper">
      <img src="assets/images/error-cannot-find.png" alt="" />
    </div>
    <div class="content-wrapper">
      <span>{{ 'UPDATE_CARD.ERROR' | translate }}.</span>
    </div>
  </div>
  <div class="button-wrapper">
    <button mat-flat-button color="primary" (click)="logOut()" *ngIf="!from_invoice_checker">
      {{ 'UPDATE_CARD.LOGOUT' | translate }}
    </button>
  </div>
</div>
