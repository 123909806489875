import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  public breadcrumb: string;

  constructor(public router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.route.children.length) {
      this.route.children[0].data.subscribe((data) => {
        this.breadcrumb = data.breadcrumb;
      });
    }
  }
}
