import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VideoLibraryService {
  constructor(private apiService: ApiService) {}

  getVideos(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/video-library/', params);
  }

  deleteVideo(videoId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/video-library/${videoId}/`);
  }
}
