import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services';
import { CommonHelpers } from '@app/helpers/common';
import { UserService, ZohoService } from '@app/shared';
import { NotifierService } from 'angular-notifier';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-package-expiry-strip',
  templateUrl: './package-expiry-strip.component.html',
  styleUrls: ['./package-expiry-strip.component.scss'],
})
export class PackageExpiryStripComponent implements OnInit {
  public accountInfo$ = this.authService.getAccountInfo();
  public displayTrialStrip: boolean = false;
  public days: string = '00';
  public hours: string = '00';
  public minutes: string = '00';
  public seconds: string = '00';
  public isUnlocking: boolean = false;

  constructor(
    private authService: AuthService,
    private zohoService: ZohoService,
    private userService: UserService,
    private notify: NotifierService
  ) {}

  ngOnInit() {
    this.authService.getPackageDetails().subscribe(
      (res) => {
        const { mode, start_date, next_invoice_date, trial_end, plan } = res;
        const startDate = new Date(start_date);
        let trialEndDate = new Date(start_date);
        if (plan) {
          if (plan === 'plan_004' && mode === 'trial') {
            if (trial_end == 'None') {
              // trialEndDate.setDate(startDate.getDate() + 7);
              trialEndDate = new Date(start_date);
            } else {
              trialEndDate = new Date(trial_end);
            }
            this.displayTrialStrip = true;
            this.calculateRemainingTime(trialEndDate);
            // Update the remaining time every second
            setInterval(() => {
              this.calculateRemainingTime(trialEndDate);
            }, 1000);
          }
        }
      },
      (error) => {
        this.displayTrialStrip = false;
      }
    );
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  calculateRemainingTime(trialEndDate: Date) {
    const currentTime = new Date();
    const timeDiff = trialEndDate.getTime() - currentTime.getTime();

    // Calculate remaining days, hours, minutes, and seconds
    const d = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const h = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const m = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const s = Math.floor((timeDiff % (1000 * 60)) / 1000);

    if (d > -1) {
      this.days = `${d}`;
      this.days = d < 10 ? `0${this.days}` : `${d}`;
    }
    if (h > -1) {
      this.hours = `${h}`;
      this.hours = h < 10 ? `0${this.hours}` : `${h}`;
    }
    if (m > -1) {
      this.minutes = `${m}`;
      this.minutes = m < 10 ? `0${this.minutes}` : `${m}`;
    }
    if (s > -1) {
      this.seconds = `${s}`;
      this.seconds = s < 10 ? `0${this.seconds}` : `${s}`;
    }

    // this.days = `${d}`;
    // this.hours = `${h}`;
    // this.minutes = `${m}`;
    // this.seconds = `${s}`;

    // Add leading zeros for single-digit values

    // this.days = d < 10 ? `0${this.days}` : `${d}`;
    // this.hours = h < 10 ? `0${this.hours}` : `${h}`;
    // this.minutes = m < 10 ? `0${this.minutes}` : `${m}`;
    // this.seconds = s < 10 ? `0${this.seconds}` : `${s}`;
  }

  handleUpgradePlan() {
    this.isUnlocking = true;
    this.authService
      .getAccountInfo()
      .pipe(first())
      .subscribe((account) => {
        this.zohoService
          .changeSubscription({
            action: 'change_plan',
            data: {
              coupon_code: '',
              plan_code: account.plan,
              addons: [],
              end_trial_period: '0',
            },
          })
          .subscribe(
            (res) => {
              this.isUnlocking = false;
              const result = CommonHelpers.transformResponseFromZoho(res);
              if (result.success) {
                this.notify.notify('success', result.message);
                this.displayTrialStrip = false;
              } else {
                if (result.redirect) {
                  window.location.href = result.message;
                  return;
                } else {
                  this.notify.notify('error', result.message);
                }
              }
            },
            (err) => {
              this.notify.notify('error', err);
              this.isUnlocking = false;
            }
          );
      });
  }
}
