<div mat-dialog-content class="mat-tut-wrapper">
  <h3>WATCH TUTORIAL</h3>
  <div class="flex-p-y video-holder">
    <video class="video-js vjs-default-skin vjs-16-9" *ngIf="!videoSource"></video>
    <app-videojs-tutorial-player
      *ngIf="videoSource"
      [options]="{
        autoplay: true,
        controls: true,
        controlBar: {
          liveDisplay: true,
          pictureInPictureToggle: false
        },
        sources: [
          {
            src: videoSource,
            type: 'video/mp4'
          }
        ]
      }"
      [videoParameters]="{
        height: videoHeight,
        width: videoWidth
      }"
    >
    </app-videojs-tutorial-player>
  </div>
  <h3 *ngIf="data.length > 1">TUTORIAL LIST</h3>
  <ul *ngIf="data.length > 1" class="tut-container">
    <li *ngFor="let item of data; let i = index">
      <div class="tutorial-item">
        <img class="tutorial-thumbnail" [src]="item.thumbnail" (click)="openTutorial(i)" />
        <div>
          <a (click)="openTutorial(i)">{{ item.title }}</a>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </li>
  </ul>
</div>
