import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { map } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeaturedItemsService {
  private readonly isLoadingFeaturedItems$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {}

  setIsLoadingFeaturedItems(isLoading: boolean) {
    this.isLoadingFeaturedItems$.next(isLoading);
  }

  getIsLoadingFeaturedItems() {
    return this.isLoadingFeaturedItems$.asObservable().pipe(distinctUntilChanged());
  }

  getFeaturedItems(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get(`/api/v3/featured-items/`, params);
  }

  sortFeaturedItems(data: any): Observable<any> {
    return this.apiService.post(`/api/v3/featured-items/change-order/`, data);
  }
}
