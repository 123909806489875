import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TutorialType } from '@app/app.constants';
import { ConfirmDialogComponent, TutorialListMatDialogComponent } from '@app/shared/components';
import { Tutorial } from '@app/shared/domain/tutorial.domain';
import { Tutorials } from './tutorial.constants';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  tutorialList: Tutorial[];
  constructor(private i18nService: I18nService, private router: Router, private readonly dialog: MatDialog) {
    this.tutorialList = Tutorials;
  }

  // getAllTutorials() {
  //   return this.tutorialList;
  // }

  getAllTutorials() {
    return [
      {
        id: 1,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL1'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE1'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION1'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE1'
        )}`,
        watchStatus: 1,
        duration: 194,
        tags: [TutorialType.Dashboard],
      },
      {
        id: 2,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL2'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE2'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION2'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE2'
        )}`,
        watchStatus: 0,
        duration: 204,
        tags: [TutorialType.VideoLibrary],
      },
      {
        id: 3,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL3'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE3'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION3'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE3'
        )}`,
        watchStatus: 0,
        duration: 320,
        tags: [TutorialType.AppSettings],
      },
      {
        id: 4,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL4'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE4'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION4'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE4'
        )}`,
        watchStatus: 0,
        duration: 190,
        tags: [TutorialType.VideoLibrary, TutorialType.AudioLibrary],
      },
      {
        id: 5,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL5'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE5'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION5'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE5'
        )}`,
        watchStatus: 0,
        duration: 406,
        tags: [TutorialType.VideoLibrary],
      },
      {
        id: 6,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL6'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE6'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION6'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE6'
        )}`,
        watchStatus: 0,
        duration: 172,
        tags: [TutorialType.AudioLibrary],
      },
      {
        id: 7,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL7'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE7'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION7'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE7'
        )}`,
        watchStatus: 0,
        duration: 288,
        tags: [TutorialType.VideoOnDemand],
      },
      {
        id: 8,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL8'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE8'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION8'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE8'
        )}`,
        watchStatus: 0,
        duration: 195,
        tags: [TutorialType.VideoOnDemand, TutorialType.VideoPlaylist],
      },
      {
        id: 9,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL9'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE9'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION9'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE9'
        )}`,
        watchStatus: 0,
        duration: 262,
        tags: [TutorialType.VideoOnDemand, TutorialType.VideoPlaylist, TutorialType.AudioPlaylist],
      },
      {
        id: 10,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL10'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE10'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION10'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE10'
        )}`,
        watchStatus: 0,
        duration: 120,
        tags: [TutorialType.Subscription],
      },
      {
        id: 11,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL11'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE11'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION11'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE11'
        )}`,
        watchStatus: 0,
        duration: 321,
        tags: [TutorialType.Subscription],
      },
      {
        id: 12,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL12'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE12'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION12'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE12'
        )}`,
        watchStatus: 0,
        duration: 121,
        tags: [TutorialType.Subscription],
      },
      {
        id: 13,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL13'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE13'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION13'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE13'
        )}`,
        watchStatus: 0,
        duration: 211,
        tags: [TutorialType.Subscription],
      },
      {
        id: 14,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL14'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE14'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION14'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE14'
        )}`,
        watchStatus: 0,
        duration: 500,
        tags: [TutorialType.Restream],
      },
      {
        id: 15,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL15'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE15'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION15'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE15'
        )}`,
        watchStatus: 0,
        duration: 490,
        tags: [TutorialType.Restream],
      },
      {
        id: 16,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL16'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE16'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION16'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE16'
        )}`,
        watchStatus: 0,
        duration: 350,
        tags: [TutorialType.Dashboard],
      },
      {
        id: 17,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL17'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE17'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION17'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE17'
        )}`,
        watchStatus: 0,
        duration: 220,
        tags: [TutorialType.AppSettings],
      },
      {
        id: 18,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL18'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE18'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION18'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE18'
        )}`,
        watchStatus: 0,
        duration: 100,
        tags: [TutorialType.AudioLibrary],
      },
      {
        id: 19,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL19'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE19'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION19'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE19'
        )}`,
        watchStatus: 0,
        duration: 210,
        tags: [TutorialType.VideoPlaylist],
      },
      {
        id: 20,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL20'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE20'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION20'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE20'
        )}`,
        watchStatus: 0,
        duration: 400,
        tags: [TutorialType.VideoLibrary],
      },
      {
        id: 21,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL21'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE21'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION21'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE21'
        )}`,
        watchStatus: 0,
        duration: 180,
        tags: [TutorialType.VideoOnDemand],
      },
      {
        id: 22,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL22'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE22'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION22'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE22'
        )}`,
        watchStatus: 0,
        duration: 250,
        tags: [TutorialType.AudioLibrary],
      },
      {
        id: 23,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL23'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE23'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION23'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE23'
        )}`,
        watchStatus: 0,
        duration: 340,
        tags: [TutorialType.VideoOnDemand],
      },
      {
        id: 24,
        thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.THUMBNAIL24'
        )}`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE24'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION24'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE24'
        )}`,
        watchStatus: 0,
        duration: 410,
        tags: [TutorialType.VideoLibrary],
      },
      {
        id: 25,
        // thumbnail: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation('TUTORIALS.THUMBNAIL25')}`,
        thumbnail: `/assets/img/app/imgpsh_fullsize.png`,
        title: this.i18nService.getTranslation('TUTORIALS.TITLE25'),
        description: this.i18nService.getTranslation('TUTORIALS.DESCRIPTION25'),
        source: `${environment.videoTutorialCDN}/video-tutorials/${this.i18nService.getTranslation(
          'TUTORIALS.SOURCE25'
        )}`,
        watchStatus: 0,
        duration: 320,
        tags: [TutorialType.AudioLibrary],
      },
    ];
  }

  getTutorialById(id: number) {
    return this.tutorialList.find((tut) => tut.id === id);
  }

  showTutorial(type: TutorialType) {
    const filteredList = this.tutorialList.filter((tut) => tut.tags && tut.tags.indexOf(type) >= 0);
    if (filteredList.length === 0) {
      return;
    }
    // if (filteredList.length === 1) {
    //   this.router.navigate(['/video-tutorials'], {
    //     queryParams: {
    //       videoId: filteredList[0].id,
    //     },
    //   });
    //   return;
    // }
    const dialogRef = this.dialog.open(TutorialListMatDialogComponent, {
      maxWidth: '750px',
      data: filteredList,
      panelClass: 'responsive-tutorial-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
    return;
  }
}
