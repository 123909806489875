import { Audio } from './audio.domain';
import { Video } from './video.domain';

export interface Category {
  uuid: string;
  title: string;
  description: string;
  slug: string;
  image: string;
  status: string;
  start_date: Date;
  is_featured_item: boolean;
  active: boolean;
  videos?: Video[];
  audios?: Audio[];
  parent: Category;
  type: string;
  user: number;
  children: Category[];
  items?: any[];
  sort_order?: number;
  vertical_image: string;
  title_logo: string;
}

export class CategoryFlatNode {
  constructor(
    public expandable: boolean,
    public level: number,
    public uuid: string,
    public title: string,
    public description: string,
    public slug: string,
    public image: string,
    public status: string,
    public start_date: Date,
    public featured: boolean,
    public active: boolean,
    public videos: Video[],
    public type: string
  ) {}
}
