import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { TopVODVideos } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly selectedRange$: BehaviorSubject<any> = new BehaviorSubject<any>({
    title: '7 Days',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  constructor(private apiService: ApiService) {}

  setSelectedRange(value: any) {
    this.selectedRange$.next(value);
  }

  getSelectedRange() {
    return this.selectedRange$.asObservable().pipe(distinctUntilChanged());
  }

  getTotalViewsHours(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-total-views-hours/`, params);
  }

  getTotalDeviceInstall(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-total-device-install/`, params);
  }

  getWhenViewerWatch(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-when-viewer-watch/`, params);
  }

  getLivestreamVsOndemand(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-livestream-vs-ondemand/`, params);
  }

  getHowViewersWatch(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-how-viewers-watch/`, params);
  }

  getEarningReportByType(type: number, startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('type', type.toString());
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-earning-report-by-type/`, params);
  }

  getTopVideoPlaylist(channel: string, startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('channel', channel);
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-top-video-playlist/`, params);
  }

  getTopVod(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-top-vod/`, params);
  }

  getTopVodVideos(startDate: string, endDate: string, search: string): Observable<TopVODVideos> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    if (search) {
      params = params.set('q', search);
    }
    return this.apiService.get(`/api/v1/stats/get-top-vod-videos/`, params);
  }

  getTopAudioPlaylist(channel: string, startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('channel', channel);
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-top-audio-playlist/`, params);
  }

  getTopAod(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-top-aod/`, params);
  }

  getTopLocation(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-top-location/`, params);
  }

  getStorageReport(startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    return this.apiService.get(`/api/v1/stats/get-analytics-overview/`, params);
  }

  getStorageReportByMonth(bandwidth_month: string, storage_month: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('bandwidth_month', bandwidth_month);
    params = params.set('storage_month', storage_month);
    return this.apiService.get(`/api/v1/stats/get-analytics-overview/`, params);
  }

  getBandwidthBreakdown(start_date: string, end_date: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', start_date);
    params = params.set('end_date', end_date);
    return this.apiService.get(`/api/v1/stats/get-bandwidth-breakdown/`, params);
  }

  getStorageBreakdown(start_date: string, end_date: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('start_date', start_date);
    params = params.set('end_date', end_date);
    return this.apiService.get(`/api/v1/stats/get-storage-breakdown/`, params);
  }
}
