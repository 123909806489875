<h1 mat-dialog-title>{{ 'CREATE_NEW_CHANNEL' | translate }}</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoadingDefault; else tplForm"></app-spinner>
  <ng-template #tplForm>
    <div class="channel-thumbnail" *ngIf="previewImage">
      <img [src]="previewImage" alt="channel-thumbnail" />
    </div>
    <input
      type="file"
      accept="image/*"
      hidden
      #inputUpload
      (change)="handleUploadChannelThumbnail($event.target.files)"
    />
    <button [style.margin-bottom]="'20px'" mat-flat-button color="primary" (click)="inputUpload.click()" class="w-100">
      UPLOAD NEW LIVE STREAM IMAGE
    </button>
    <form [formGroup]="channelForm">
      <div class="channel-fields" fxLayout="column">
        <mat-label class="text-weight-bold">Title:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="title" matInput placeholder="Please input title" />
          <mat-error
            *ngIf="f.title.invalid && f.title.touched && (f.title.errors?.required || f.title.errors?.whitespace)"
            >Title is required</mat-error
          >

          <mat-error *ngIf="f.title.invalid && f.title.touched && f.title.errors?.maxlength"
            >Length from 1 to 50 characters</mat-error
          >
        </mat-form-field>
        <mat-label class="text-weight-bold">Description:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea formControlName="description" matInput placeholder="Please input description"></textarea>
          <mat-error *ngIf="f.description.invalid && f.description.touched">Description is required</mat-error>
        </mat-form-field>
        <mat-label><span class="text-weight-bold">Stream Key:</span> <i>(Stream Key for your channel)</i></mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamKey" matInput placeholder="Please input stream key" readonly />
          <mat-error *ngIf="f.streamKey.invalid && f.streamKey.touched">Stream key is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Stream Url:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamUrl" matInput placeholder="Please input stream url" readonly />
          <mat-error *ngIf="f.streamUrl.invalid && f.streamUrl.touched">Stream url is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Channel Profile</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="channelProfile" placeholder="Please select channel profile" disabled>
            <mat-option [value]="'_2500KBPS'">1280x720@2500kbps</mat-option>
            <mat-option [value]="'_3000KBPS'">1920x1080@3000kbps</mat-option>
            <mat-option [value]="'_4000KBPS'">1920x1080@4000kbps</mat-option>
          </mat-select>
          <mat-error *ngIf="f.channelProfile.invalid && f.channelProfile.touched"
            >Channel profile is required</mat-error
          >
        </mat-form-field>
        <mat-label class="text-weight-bold">Timezone</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select #singleSelect formControlName="timezone" placeholder="Please select channel timezone">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" [formControl]="timezoneSearch"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of filteredTimezone | async" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.timezone.invalid && f.timezone.touched">Timezone is required</mat-error>
        </mat-form-field>
        <mat-checkbox color="primary" disabled formControlName="isActive">Active Channel Set as active</mat-checkbox>
        <!-- <mat-checkbox color="primary" formControlName="isDefault">Default Channel Set as active</mat-checkbox> -->
        <!-- <mat-checkbox color="primary" formControlName="isResetStreamKey"
          >Reset Stream Key Used to reset stream key for a given channel</mat-checkbox
        > -->
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleCreate()"
  >
    Create
  </button>
</div>
