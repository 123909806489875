<h1 mat-dialog-title>{{ 'BUG_REPORT_HEADER' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="reportForm">
    <div class="report-fields" fxLayout="column">
      <mat-label class="text-weight-bold">{{ 'REPORTER_NAME' | translate }}:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="name" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">{{ 'REPORTER_EMAIL' | translate }}:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="email" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">{{ 'REPORT_SUBJECT' | translate }}:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="subject" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold"> {{ 'BUG_DESCRIPTION' | translate }}:</mat-label>
      <mat-form-field appearance="outline">
        <textarea matInput placeholder="" rows="6" formControlName="description"></textarea>
      </mat-form-field>
      <div *ngIf="previewUrl" class="image-preview" style="position: relative">
        <img [src]="previewUrl" alt="Image preview" />
        <div class="closeBtn" (click)="removeAttachment()">
          <mat-icon style="padding: 5px">close</mat-icon>
        </div>
      </div>
      <div fxFlex="60">
        <button mat-flat-button color="primary" (click)="fileInput.click()" type="button" class="button-200">
          {{ 'BUG_ATTACHMENTS' | translate }}
        </button>
        <input #fileInput type="file" accept="image/*" hidden (change)="handleScreenShots($event.target.files)" />
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-flat-button class="mat-small" color="primary" (click)="handleSend()">
    {{ 'BUG_REPORT_SEND' | translate }}
  </button>
</div>
