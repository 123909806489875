<div *ngIf="displayTrialStrip" fxLayout="row wrap" fxLayoutAlign="space-around center" class="package-expiry-strip">
  <div>
    <h4>Your Trial period is Expiring Soon!</h4>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" class="timer">
      <span class="timer-count">{{ days }}</span>
      <span class="timer-text">DAY</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="timer">
      <span class="timer-count">{{ hours }}</span>
      <span class="timer-text">HRS</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="timer">
      <span class="timer-count">{{ minutes }}</span>
      <span class="timer-text">MIN</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="timer">
      <span class="timer-count">{{ seconds }}</span>
      <span class="timer-text">SEC</span>
    </div>
  </div>
  <div>
    <button class="upgrade-btn" mat-flat-button [loading]="isUnlocking" (click)="handleUpgradePlan()">
      Upgrade Now >>
    </button>
  </div>
</div>
