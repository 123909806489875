<h1 mat-dialog-title>{{ 'CREATE_NEW_PLAYLIST' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="playlistForm">
    <div class="playlist-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Title:</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <input type="text" formControlName="title" matInput placeholder="Please input title" />
        <mat-error *ngIf="f.title.invalid && f.title.touched">Invalid title</mat-error>
      </mat-form-field>
      <mat-label class="text-weight-bold">Description:</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <textarea
          type="description"
          formControlName="description"
          matInput
          placeholder="Please input description"
        ></textarea>
        <mat-error *ngIf="f.description.invalid && f.description.touched">Description is required</mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxLayout="column" class="w-100">
          <mat-label class="text-weight-bold">Mode:</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select formControlName="mode" (selectionChange)="handleModeChange($event)">
              <mat-option *ngFor="let item of configConstans.playlistModes | keyvalue" [value]="item.key">{{
                item.value['value']
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="f.mode.invalid && f.mode.touched">Mode is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="w-100">
          <mat-label class="text-weight-bold">Status:</mat-label>
          <mat-form-field appearance="outline" class="w-100 readonly-wrapper">
            <input type="text" formControlName="status" matInput placeholder="Please status stream url" readonly />
            <mat-error *ngIf="f.status.invalid && f.status.touched">Status is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="f.mode.value === configConstans.playlistModes.SCHEDULED.value" fxLayout="row" fxLayoutGap="16px">
        <div fxLayout="column" class="w-100">
          <mat-label class="text-weight-bold">Start Date:</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              type="text"
              [min]="minDate"
              [owlDateTimeTrigger]="date"
              [owlDateTime]="date"
              formControlName="startDate"
              matInput
              placeholder="Please select start date"
            />
            <owl-date-time [pickerType]="'calendar'" #date></owl-date-time>
            <mat-error *ngIf="f.startDate.invalid && f.startDate.touched">Start date is required</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="w-100">
          <mat-label class="text-weight-bold">Start Time:</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              type="text"
              [owlDateTimeTrigger]="time"
              [owlDateTime]="time"
              formControlName="startTime"
              matInput
              placeholder="Please select start time"
            />
            <owl-date-time [pickerType]="'timer'" [hour12Timer]="true" #time></owl-date-time>
            <mat-error *ngIf="f.startTime.invalid && f.startTime.touched">Start time is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf="f.mode.value === configConstans.playlistModes.SCHEDULED.value"
        fxLayout="row"
        fxLayoutGap="16px"
        style="font-size: 12px"
      >
        Note: The scheduled playlist will remain a draft until video content is added.
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleCreate()"
  >
    Create
  </button>
</div>
