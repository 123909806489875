// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// import { env } from './.env';

export const environment = {
  production: true,
  // version: env.npm_package_version,
  baseUrl: 'http://localhost:4000',
  uploadServerUrl: 'https://uploader.tvstartupmedia.com',
  enginePusherUrl: 'https://uploader-stg.tvsdevops.com',
  qmsUploadUrl: 'https://qms.tvsdevops.com',
  websiteUrl: 'https://cms.tvsdevops.com',
  serverUrl: 'https://api.tvsdevops.com',
  wsUrl: 'wss://api.tvsdevops.com/ws',
  sitePublisherUrl: 'https://api.tvstest.com',
  sitePublisherDomain: 'tvstest.com',
  wsHeartbeatUrl: 'wss://ws.postman-echo.com/raw',
  // mediaUrl: 'https://tvsa1-thumb.secdn.net/tvsa1-vod/play/sestore3/tvsa1/tvs-staging/media',
  // staticCDNUrl: 'https://tvsa1-vod.secdn.net/tvsa1-vod/play/sestore3/tvsa1/tvs-staging/static',
  mediaUrl: 'https://tvsw1-vod.secdn.net/tvsw1-vod/play/sestore3/tvsw1/tvs-staging/media',
  staticCDNUrl: 'https://tvsw1-vod.secdn.net/tvsw1-vod/play/sestore3/tvsw1/tvs-staging/static',
  videoTutorialCDN: 'https://tvs-assets.b-cdn.net',
  defaultLanguage: 'en',
  supportedLanguages: ['en', 'es', 'jp', 'fr', 'da', 'de', 'ru', 'tw', 'cn', 'kr'],
  objectStorageUrl: 'https://cms.tvsdevops.com/clients',
  siteBuilderCDNUrl: 'https://tvs-sandbox.b-cdn.net/static/site-builder',
  googleAPIKey: 'AIzaSyBH2MJsw3zrSDXh0lix_taYR0TzrXiXcZs',
  cryptoKey: 'tvstartupfeatures',
  streamProduction: false,
  playerUrl: 'https://tvsa1-origin.secdn.net/tvsa1/',
  streamUrl: 'https://tvsw1-hls.secdn.net/tvsw1-channel/play/',
  antSocketUrl: 'ws://tvsa1-origin.secdn.net/tvsa1/websocket',
  recordingUrl: 'https://tvsw1-vod.secdn.net/tvsw1-vod/play/sestore3/tvsw1/',
  recordingSEServerOld: 'tvsa1',

  // VOD Playlist Limit
  vodPlayListLimit: 18000,
  twitch: 'https://tvstartup-d738e.web.app',
  twitchClientId: 'su3892yicnrws7d4yrn880nela12bp',
  youtubeClientId: '916504886303-r64pbg1d8mvodl6213p3jr0uh3392lcs.apps.googleusercontent.com',
  youtubeApiKey: 'AIzaSyDm5dXfkVL57QaihtzIPcrJ2oQ72G9Us9Q',
  jitsiDomain: 'meet.jit.si',
  qmsUploaderAPIKey: 'Api-Key wlgivZaM.Tv73oYmAYmnFBNEKv166asScxN0EeVIK',
  qmsEnvServer: 'cms.tvsdevops.com',
};
