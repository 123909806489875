import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { environment as env } from '@env/environment';

export interface S3CreateConfig {
  awsAccessKeyId: string;
  aws_endpoint: string;
  aws_protocol: string;
  aws_s3_bucket_name: string;
  aws_secret_access_key: string;
  region_name: string;
  tenant_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class S3ImporterService {
  constructor(private apiService: ApiService) {}

  fetchYoutubeVideos(payload: any) {
    let url = `${env.serverUrl}/api/v3/importers/`;
    return this.apiService.post(url, payload);
  }

  createS3Credentials(payload: S3CreateConfig) {
    let url = `${env.serverUrl}/api/v3/importers/`;
    return this.apiService.post(url, payload);
  }

  fetchS3ImportHistory(params: any) {
    let url = `${env.serverUrl}/api/v3/s3-import-history`;
    const queryString = new URLSearchParams(params).toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return this.apiService.get(url);
  }

  deleteImportItem(uuid: string) {
    let url = `${env.serverUrl}/api/v3/s3-import-history/${uuid}`;
    return this.apiService.delete(url);
  }
}
