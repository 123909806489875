import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChannelService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { EditChannelDialogComponent } from '..';
import { ExternalChannelDialogComponent, ChannelExternalDialogModel } from '../external-channel-dialog';

@Component({
  selector: 'app-external-audio-channel-dialog',
  templateUrl: '../external-channel-dialog/external-channel-dialog.component.html',
  styleUrls: ['../external-channel-dialog/external-channel-dialog.component.scss'],
})
export class ExternalAudioChannelDialogComponent extends ExternalChannelDialogComponent {
  constructor(
    protected formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditChannelDialogComponent>,
    protected notifier: NotifierService,
    protected channelService: ChannelService,
    @Inject(MAT_DIALOG_DATA) public data: ChannelExternalDialogModel
  ) {
    super(formBuilder, dialogRef, notifier, channelService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected prepareFormData(): FormData {
    const fmData = super.prepareFormData();
    fmData.append('content_type', 'AUDIO');
    return fmData;
  }
}
