<div class="container">
  <h2>Choose Option</h2>
  <p style="padding: 15px 0px" *ngIf="account_type == 'dropbox'">
    A {{ account_type }} account is already linked to this.
    <b
      >If you wish to select a new dropbox account, first logout your existing account in your browser and select access
      new account.</b
    >
  </p>
  <p style="padding: 15px 0px" *ngIf="account_type == 'vimeo'">
    A {{ account_type }} account is already linked to this. Please decide whether to utilize the existing
    {{ account_type }} account or grant access to another account.
  </p>
  <div style="display: flex; align-items: center; justify-content: flex-end; margin: 20px; gap: 10px">
    <button mat-raised-button (click)="select('proceed')">Proceed</button>
    <button mat-raised-button (click)="select('new')" color="primary">Access New Account</button>
  </div>
</div>
