import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Logger } from '../logger.service';

import enUS from '../../../assets/i18n/en.json';
import español from '../../../assets/i18n/es.json';
import japanese from '../../../assets/i18n/jp.json';
import french from '../../../assets/i18n/fr.json';
import danish from '../../../assets/i18n/da.json';
import german from '../../../assets/i18n/de.json';
import russian from '../../../assets/i18n/ru.json';
import traditional from '../../../assets/i18n/tw.json';
import simplified from '../../../assets/i18n/cn.json';
import korean from '../../../assets/i18n/kr.json';

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage: string = 'en';
  supportedLanguages!: string[];

  private langChangeSubject = new BehaviorSubject<string>(this.defaultLanguage);
  langChange$ = this.langChangeSubject.asObservable();
  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService) {
    this.translateService.setTranslation('en', enUS);
    this.translateService.setTranslation('es', español);
    this.translateService.setTranslation('jp', japanese);
    this.translateService.setTranslation('fr', french);
    this.translateService.setTranslation('da', danish);
    this.translateService.setTranslation('de', german);
    this.translateService.setTranslation('ru', russian);
    this.translateService.setTranslation('tw', traditional);
    this.translateService.setTranslation('cn', simplified);
    this.translateService.setTranslation('kr', korean);

    this.supportedLanguages = ['en', 'es', 'jp', 'fr', 'da', 'de', 'ru', 'tw', 'cn', 'kr'];

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChangeSubject.next(event.lang);
    });
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages.length > 0 ? supportedLanguages : this.supportedLanguages;
    this.language = '';

    // Warning: this subscription will always be alive for the app's lifetime
    // this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
    //   localStorage.setItem(languageKey, event.lang);
    //   this.langChangeSubject.next(event.lang); // Notify subscribers of language change
    // });

    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang(this.defaultLanguage);
  }

  getCurrentLanguage() {
    return localStorage.getItem(languageKey) ? localStorage.getItem(languageKey) : this.defaultLanguage;
  }

  changeLanguage(lang: string) {
    this.langChangeSubject.next(lang);
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    this.translateService.use(language);
  }

  /**
   * Sets the language to equivalent translation
   * @param language language string
   */
  setLanguage(language: string): void {
    this.langChangeSubject.next(language);
  }

  /**
   * Gets translation based on key
   * @param key translation key
   * @returns
   */
  getTranslation(key: string): any {
    let translation: string;
    this.translateService.get(key).subscribe((trans) => (translation = trans));
    return translation;
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }
}
