<div mat-dialog-content>
  <div fxLayout="column">
    <ngx-file-drop
      *ngIf="!this.fileName"
      class="ngx-file-drop"
      dropZoneLabel="Drop files here"
      (onFileDrop)="dropped($event)"
      [accept]="'.csv'"
    >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div fxLayout="column" fxLayoutAlign="center center">
          <img src="./assets/images/upload.png" alt="upload-icon" class="image-upload" [style.width]="'20px'" />
          <div [style.color]="'black !important'">{{ 'DRAG_DROP_CSV_ONLY' | translate }}</div>
          <button mat-flat-button color="primary" class="mat-small flex-m-y" (click)="openFileSelector()">
            Browse
          </button>
        </div>
      </ng-template>
    </ngx-file-drop>
    <div class="file-upload" *ngIf="this.fileName">File Name : {{ fileName }}</div>
  </div>
  <ng-container *ngIf="!!subscriber.length">
    <div fxLayout="row" [style.marginTop]="'20px'" class="border-bottom">
      <span fxFlex class="text-weight-bold">ENTRY</span>
      <span fxFlex class="text-weight-bold">FIELD</span>
    </div>

    <form [formGroup]="fieldForm" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[0] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field1">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error [style.marginTop]="'14px'" *ngIf="f.field1?.invalid">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[1] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field2">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.field2?.invalid" [style.marginTop]="'14px'">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[2] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field3">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.field3?.invalid" [style.marginTop]="'14px'">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[3] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field4">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.field4?.invalid" [style.marginTop]="'14px'">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[4] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field5">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.field5?.invalid" [style.marginTop]="'14px'">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <span fxFlex="50" class="text-weight-bold">{{ subscriber[5] }}</span>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="field6">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{ field.display }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.field6?.invalid" [style.marginTop]="'14px'">The same another field</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <div class="text-weight-bold" fxFlex="50">Select channel</div>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="channel_uuid">
            <mat-option *ngFor="let c of channelList$ | async" [value]="c.uuid">
              {{ c.title }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.channel_uuid?.invalid" [style.marginTop]="'14px'">Please select a channel</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <div class="text-weight-bold" fxFlex="50">Select plan</div>
        <mat-form-field appearance="outline" fxFlex="35">
          <mat-select formControlName="subs_plan_uuid">
            <app-spinner *ngIf="isLoadingPlan"></app-spinner>
            <ng-container *ngIf="!isLoadingPlan">
              <mat-option *ngFor="let plan of plans" [value]="plan.uuid">
                {{ plan.title }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="f.subs_plan_uuid?.invalid" [style.marginTop]="'14px'">Please select a plan</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
        <div class="text-weight-bold" fxFlex="50">
          <div>Add Generic Password For Imports(Optional)</div>
          <div style="font-size: 10px">if no password provided, username will be used as password</div>
        </div>
        <mat-form-field appearance="outline" fxFlex="35">
          <input
            matInput
            placeholder="Enter Password"
            formControlName="password"
            name="genericPassword"
            type="password"
          />
          <mat-error *ngIf="f.password?.hasError('minlength')" [style.marginTop]="'14px'"
            >Password must be at least 8 characters</mat-error
          >
        </mat-form-field>
      </div>
    </form>
  </ng-container>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
  <button mat-flat-button class="mat-small" color="grey" (click)="handleCancel()" *ngIf="!!subscriber.length">
    Cancel
  </button>
  <button mat-flat-button class="mat-small" color="primary" (click)="handleImportCSV()" *ngIf="!!subscriber.length">
    Import
  </button>
</div>
