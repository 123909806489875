import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { PageEvent } from '@angular/material/paginator';
import { interval, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { S3ImporterService } from '@app/shared/services/s3-importer';
import { VideoImporterModelV2 } from '../video-importer-v2';
import { AuthService } from '@app/auth/services';

@Component({
  selector: 'app-video-s3-importer',
  templateUrl: './s3-importer.component.html',
  styleUrls: ['./s3-importer.component.scss'],
})
export class S3ImporterComponent implements OnInit {
  selectedVideos: string[] = [];
  hasError: boolean = false;
  selectedPageSize: number = 10;
  viewImporter: boolean = false;
  query_params = {
    import_from: '',
    page_size: 10,
    page: 1,
    status: '',
    search: '',
  };
  importHistory: any[];
  isFetchingImportHistory: boolean = false;
  videos: any[] = [];
  isImporting: boolean = false;
  isFetching: boolean = false;
  contentUrl: string = '';
  type: string = '';
  importHistoryTotalItems: number = 0;
  selectAllReImport: boolean = false;
  selectedItemsToReImport: any = [];
  selectedPage: number = 1;
  totalItems: number = 0;
  availablePages: number[] = [];
  tenant_id: string;
  private intervalSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private s3ImporterApiService: S3ImporterService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<VideoImporterModelV2>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
  }

  s3Form: FormGroup;

  ngOnInit(): void {
    this.authService
      .getAccountInfo()
      .pipe(filter((s) => !!s))
      .subscribe((acc: any) => {
        this.tenant_id = acc.tenant_id;
      });

    this.s3Form = this.fb.group({
      aws_access_key_id: ['', [Validators.required]],
      aws_secret_access_key: ['', [Validators.required]],
      aws_s3_bucket_name: ['', [Validators.required]],
      region_name: ['', [Validators.required]],
      aws_endpoint: ['', [Validators.required]],
      aws_protocol: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.s3Form.valid) {
      this.s3ImporterApiService.createS3Credentials({ ...this.s3Form.value, tenant_id: this.tenant_id }).subscribe({
        next: (response) => {
          this.snackBar.open('S3 Credentials Saved!', 'Close', { duration: 2000 });
          this.notifier.notify('success', `S3 Account stored. Import automation will start soon.`);
        },
        error: (error) => {
          this.snackBar.open('Error saving credentials!', 'Close', { duration: 3000 });
        },
      });
    }
  }

  selectVideo(video: any) {
    const videoUrl = video.url;

    const index = this.selectedVideos.indexOf(videoUrl);

    if (index === -1) {
      this.selectedVideos.push(videoUrl);
    } else {
      this.selectedVideos.splice(index, 1);
    }
  }

  selectAll() {
    const allVideoUrls = this.videos.map((video) => video.url);
    const newSelections = allVideoUrls.filter((url) => !this.selectedVideos.includes(url));
    this.selectedVideos.push(...newSelections);
  }

  handleImportSelectedVideos() {
    const dialogData = new ConfirmDialogModel(
      'Note',
      `Selected ${this.selectedVideos.length} ${
        this.selectedVideos.length > 1 ? 'videos' : 'video'
      } to import. Kindly note that there may be a delay in imported videos appearing in the video library. We appreciate your patience during this process.`,
      'Confirm'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '360px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.type == 'S3') {
          // trigger s3 start download
        }
      }
    });
  }

  fetchS3ImportHistory(status: string = '', hideLoading: boolean = false) {
    if (status == 'all') {
      this.query_params.status = '';
    }
    this.viewImporter = false;
    this.query_params.import_from = this.type;
    this.hasError = false;
    this.s3ImporterApiService.fetchS3ImportHistory(this.query_params).subscribe(
      (data: any) => {
        this.importHistory = data.results.map((item: any) => ({
          ...item,
          selected: false,
        }));
        this.importHistoryTotalItems = data.total;
        this.isFetchingImportHistory = false;
      },
      (error) => {
        this.hasError = true;
        this.isFetchingImportHistory = false;
      }
    );
  }

  onFilterOptionSelected(job_status: string) {
    if (job_status == 'all') {
      this.query_params.status = '';
    } else {
      this.query_params.status = job_status;
    }

    this.handleSearchVideo(null);
  }

  handleSearchVideo(e: string) {
    this.query_params.page_size = 10;
    this.query_params.page = 1;
    this.fetchS3ImportHistory();
  }

  onPageChange(event: PageEvent): void {
    this.query_params.page_size = event.pageSize;
    this.query_params.page = event.pageIndex + 1;
    this.fetchS3ImportHistory();
  }

  getStatus(status: string): string {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'in_progress':
        return 'In Progress';
      case 'downloading':
        return 'Downloading';
      case 'uploaded':
        return 'Uploaded';
      case 'failed':
        return 'Failed';
      default:
        return 'Unknown';
    }
  }

  toggleSelectAll(checked: boolean): void {
    this.importHistory.forEach((item) => (item.selected = checked));
    this.updateSelectedCount();
  }

  updateSelectedCount(): void {
    this.selectedItemsToReImport = this.importHistory
      .filter((item) => item.selected) // Filter selected items
      .map((item) => item.uuid); // Map to UUIDs
  }

  getAvailablePages() {
    const totalPages = Math.ceil(this.totalItems / this.selectedPageSize);
    this.availablePages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  viewForm() {
    this.viewImporter = true;
    this.selectedItemsToReImport = [];
  }
}
