import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsTransform',
})
export class SecondsTransformPipe implements PipeTransform {
  transform(value: number): string {
    let minutes: number = Math.trunc(value / 60);
    let hours: number = 0;

    if (minutes >= 60) {
      hours = Math.trunc(minutes / 60);
      minutes = minutes - hours * 60;
    }

    let response: string = '';
    if (hours === 0 && minutes < 0.01) return (response = '0.01');
    if (hours > 0) {
      response = response + hours;
    }

    if (minutes > 0) {
      response = response + (minutes / 60).toFixed(2);
    }
    return response;
  }
}
