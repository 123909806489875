import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@app/auth/services';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  public menuItems: Array<any>;
  public settings: Settings;
  public accountInfo$ = this.authService.getAccountInfo();
  private langChangeSubscription: Subscription;
  public selectedLanguage: string;
  public selectedLanguageIcon: string;
  constructor(
    private appSettings: AppSettings,
    private menuService: MenuService,
    private authService: AuthService,
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();

    this.i18nService.init('en', ['en', 'es', 'jp', 'fr', 'da', 'de', 'ru', 'tw', 'cn', 'kr']);
    this.selectedLanguage = this.i18nService.getCurrentLanguage();
    this.langChangeSubscription = this.i18nService.langChange$.subscribe((lang) => {
      this.translateService.use(lang);
    });
  }

  public closeSubMenus() {
    let menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
}
