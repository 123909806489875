import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { CmsLayoutComponent } from './cms-layout/cms-layout.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { GuestLayoutComponent } from './guest-layout/guest-layout.component';
import { PackageExpiryStripComponent } from './components/package-expiry-strip/package-expiry-strip.component';
import { MaterialModule } from '@app/material.module';
import { LanguageSelectorComponent } from '@app/shared/components/language-selector/language-selector.component';
import { I18nService } from '@app/core';

const COMPONENTS = [
  AuthLayoutComponent,
  CmsLayoutComponent,
  VerticalMenuComponent,
  MessagesComponent,
  SidenavComponent,
  UserMenuComponent,
  FooterComponent,
  GuestLayoutComponent,
  PackageExpiryStripComponent,
  LanguageSelectorComponent,
];

@NgModule({
  imports: [MaterialModule, SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: [I18nService],
})
export class LayoutsModule {}
