import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '@app/shared/domain';
import { VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { map } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { AssignTrailerClipComponent, AssignTrailerClipModel } from '../assign-trailer-clip';

@Component({
  selector: 'app-clip-settings-dialog',
  templateUrl: './clip-settings-dialog.component.html',
  styleUrls: ['./clip-settings-dialog.component.scss'],
})
export class ClipSettingsDialogComponent implements OnInit {
  public video: Video;
  public fileList: FileList;
  public previewThumbnail: string;
  public isLoading: boolean;
  public isFetching: boolean;
  public selectedVideo: Video;
  constructor(
    private dialog: MatDialog,
    private notifier: NotifierService,
    private videoService: VideoService,
    public dialogRef: MatDialogRef<ClipSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClipSettingsDialogModel
  ) {
    this.video = data.video;
  }

  ngOnInit() {
    this.isFetching = true;
    this.videoService.getVideo(this.video.uuid).subscribe(
      (res) => {
        this.video = res;
        this.isFetching = false;
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isFetching = false;
      }
    );
  }

  onSave(): void {
    const { uuid: videoId } = this.video;
    const fmData = new FormData();
    const _observables: Array<Observable<any>> = [];
    if (this.fileList?.length) {
      fmData.append('banner', this.fileList[0]);
    }
    if (this.selectedVideo) {
      fmData.append('trailer', this.selectedVideo.uuid);
    }
    if (fmData.has('banner') || fmData.has('trailer')) {
      this.isLoading = true;
      this.videoService.uploadVideoThumbnail(fmData, videoId).subscribe(
        (res) => {
          this.video.banner = res.banner;
          this.video.trailer = res.trailer;
          this.handleClearThumb();
          this.notifier.notify('success', 'Video successfully saved');
          this.isLoading = false;
          this.dialogRef.close(false);
        },
        (err) => {
          this.handleClearThumb();
          this.isLoading = false;
          this.notifier.notify('error', err);
        }
      );
    } else {
      this.dialogRef.close(false);
    }
  }

  handleOpenAssignTrailer() {
    const dialogData = new AssignTrailerClipModel();
    const dialogRef = this.dialog.open(AssignTrailerClipComponent, {
      width: '850px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.selectedVideo = dialogResult;
    });
  }

  handleChange(files: FileList) {
    if (files && files.length) {
      if (files.length === 0) return;
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notifier.notify('warning', 'Only images are supported');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result as string;
        this.fileList = files;
        this.previewThumbnail = event.target.result as string;
        // image.onload = () => {
        //   const height = image.naturalHeight;
        //   const width = image.naturalWidth;
        //   if (width !== 1920 || height !== 1080) {
        //     this.notifier.notify('warning', 'Image must be 1920x1080 pixels');
        //     return;
        //   }
        //   this.fileList = files;
        //   this.previewThumbnail = event.target.result as string;
        // };
      };
    }
  }

  handleClearThumb() {
    this.fileList = [] as any;
    this.previewThumbnail = null;
  }

  handleUploadThumb() {}

  handleOpenSettings() {
    window.open(`/video-library/video-settings/${this.video.uuid}`, '_blank');
  }
}

export class ClipSettingsDialogModel {
  constructor(public video: Video) {}
}
