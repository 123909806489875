<ng-container [formGroup]="controlContainer.control">
  <div class="purchase-wrap">
    <div fxLayout="column wrap" [fxFlex]="100" class="flex-p">
      <div class="label title">{{ 'PURCHASE_LINK_SETUP' | translate }}</div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-slide-toggle
          class="example-margin"
          [color]="'primary'"
          [checked]="f.get('payment_way').value === 1"
          [disabled]="f.get('payment_way').disabled"
          (change)="updatePaymentLinkType(1)"
        ></mat-slide-toggle>
        <span>{{ 'AUTO_GENERATE_PURCHASE_CODE' | translate }}</span>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px" class="select-page">
        Select Page
        <mat-form-field appearance="outline">
          <mat-select formControlName="published_site">
            <mat-option *ngFor="let sites of publishedSites" [value]="sites.uuid">{{ sites.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <mat-slide-toggle
          class="example-margin"
          [color]="'primary'"
          [checked]="f.get('payment_way').value === 2"
          [disabled]="f.get('payment_way').disabled"
          (change)="updatePaymentLinkType(2)"
        ></mat-slide-toggle>
        <span>{{ 'ENABLE_PURCHASE_FROM_EMBEDDED_CODE' | translate }}</span>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
        *ngIf="f.get('payment_way').value === 2 && qr_code_website"
      >
        <mat-label>{{ 'WEBSITE_FOR_QR_CODE' | translate }}</mat-label>
        <mat-form-field fxFlex appearance="outline">
          <input type="text" formControlName="qr_code_website" matInput placeholder="https://domain.com" />
          <mat-error *ngIf="f.get('qr_code_website').invalid && f.get('qr_code_website').touched"
            >Website url must be correct formated e.g. https://domain.com</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="w-100">
          <textarea #description matInput formControlName="payment_forms" disabled></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button class="copy-payment-forms" (click)="copyEmbeddedCode()">copy embedded code</button>
      </div>
    </div>
  </div>
</ng-container>
