import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ContentType } from '@app/app.constants';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { Channel, ChannelDefault, ChannelTimezone, Genre, Package } from '@app/shared/domain';
import { SubscriptionPlan } from '@app/shared/domain/subscription-plan.domain';
import { ChannelService, PackageService, SubscriptionService, VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-channel-dialog',
  templateUrl: './edit-channel-dialog.component.html',
  styleUrls: ['./edit-channel-dialog.component.scss'],
})
export class EditChannelDialogComponent implements OnInit {
  public channel: Channel;
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelForm: FormGroup;
  public isLoading: boolean;
  public genres: Genre[];
  public isLoadingDefault: boolean;
  public channelDefault: ChannelDefault;
  public channelPackages: Package[];
  public subscriptionPlans: SubscriptionPlan[];
  public channelTimezones: ChannelTimezone[] = [];
  public timezoneSearch: FormControl = new FormControl();
  public streamUrl: string;
  public filteredTimezone: ReplaySubject<ChannelTimezone[]> = new ReplaySubject<ChannelTimezone[]>(1);
  file: File;
  public isVisible: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditChannelDialogComponent>,
    private notifier: NotifierService,
    private channelService: ChannelService,
    private videoService: VideoService,
    private packageService: PackageService,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: EditChannelDialogModel
  ) {
    this.channel = data.channel;
  }

  ngOnInit() {
    this.getGenres();
    this.isLoadingDefault = true;
    // Get channel
    this.channelService.getChannel(this.channel.uuid).subscribe((channel) => {
      this.channel = channel;
      if (this.channel.stream_key) {
        const stream_key = this.channel.stream_key.split('-');
        const manipulated_strean_key = stream_key[0] + '-' + stream_key[2];
        this.streamUrl = this.channel.stream_hls_url_base + manipulated_strean_key + '/playlist.m3u8';
      }
    });

    forkJoin([
      this.channelService.getChannelTimezones().pipe(
        map((res) => {
          const usTimezones = res.filter((t) => t.value.startsWith('US/'));
          const anotherTimeZone = res.filter((t) => !t.value.startsWith('US/'));
          return [...usTimezones, ...anotherTimeZone];
        })
      ),
      this.packageService.getAllPackages(),
      this.subscriptionService.getListSubscriptionPlan(this.channel.uuid),
    ]).subscribe(
      ([channelTimezones, channelPackages, subscription_plans]) => {
        let sub_plans: any = [];
        if (subscription_plans) {
          sub_plans = subscription_plans.filter((item: any) => item.content_types.includes(ContentType.CHANNEL));
        }

        this.channelTimezones = channelTimezones;
        this.channelPackages = channelPackages;
        this.subscriptionPlans = sub_plans;

        this.buildForm();
        this.isLoadingDefault = false;
        this.searchInit();
      },
      () => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoadingDefault = false;
      }
    );
  }

  searchInit() {
    this.filteredTimezone.next(this.channelTimezones.slice());
    this.timezoneSearch.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTimezone();
    });
  }

  filterTimezone() {
    if (!this.channelTimezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneSearch.value;
    if (!search) {
      this.filteredTimezone.next(this.channelTimezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezone.next(this.channelTimezones.filter((time) => time.value.toLowerCase().indexOf(search) > -1));
  }

  get f() {
    return this.channelForm.controls;
  }

  getGenres() {
    const useGenreLabels = true;
    this.videoService.getGenreOptions(useGenreLabels).subscribe(
      (res) => {
        this.genres = res.genres;
      },
      () => {
        this.notifier.notify('error', 'Genre options cannot be fetched');
      }
    );
  }

  buildForm() {
    this.isVisible = this.channel?.is_visible == 'True';
    this.channelForm = this.formBuilder.group({
      title: [this.channel.title, [Validators.required, NoWhitespaceValidator()]],
      description: [this.channel.description],
      streamKey: [this.channel.stream_key, [Validators.required, NoWhitespaceValidator()]],
      streamUrl: [this.channel.stream_url, [Validators.required, NoWhitespaceValidator()]],
      plan: [this.channel.package?.uuid, []],
      live_stream_subscription_plans: [this.channel.live_stream_subscription_plans_uuids],
      channelProfile: [this.channel.channel_profile, [Validators.required]],
      timezone: [this.channel.timezone, [Validators.required]],
      genres: [this.channel.genres],
      isActive: [this.channel.active],
      isDefault: [this.channel.default],
      isVisible: [this.isVisible],
      // isResetStreamKey: [false],
    });
  }

  handleUpdate(): void {
    if (this.channelForm.invalid) {
      CommonHelpers.validateAllFormFields(this.channelForm);
      return;
    }
    this.isLoading = true;
    const {
      title,
      description,
      streamKey,
      streamUrl,
      // plan,
      live_stream_subscription_plans,
      channelProfile,
      isActive,
      isDefault,
      isVisible,
      timezone,
      genres,
    } = this.channelForm.value;
    const slug = CommonHelpers.createSlug(title);
    const fmData = new FormData();
    fmData.append('title', title);
    fmData.append('description', description);
    fmData.append('streamKey', streamKey);
    fmData.append('streamUrl', streamUrl);
    // fmData.append('plan', plan);
    fmData.append('channelProfile', channelProfile);
    fmData.append('isActive', isActive);
    fmData.append('isDefault', isDefault);
    fmData.append('is_visible', isVisible);
    fmData.append('timezone', timezone);
    if (genres.length > 0) {
      genres.forEach((genre: string) => fmData.append('genres', genre));
    } else {
      // FormData isn't able to send a blank list, so I need a special API call that uses plain JSON
      this.channelService.updateChannel({ genres: [] }, this.channel.uuid).subscribe();
    }
    if (live_stream_subscription_plans != null && live_stream_subscription_plans.length > 0) {
      for (var i = 0; i < live_stream_subscription_plans.length; i++) {
        fmData.append('live_stream_subscription_plans', live_stream_subscription_plans[i]);
      }
    } else {
      fmData.append('live_stream_subscription_plans', null);
    }

    if (this.file?.size) {
      fmData.append('image', this.file);
    }
    const { uuid: channelId } = this.channel;
    this.channelService.updateChannel(fmData, channelId).subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully updated ${title} channel`);
        this.channelService.getDropDownChannel();
        if (isDefault) this.channelService.changeActiveChannel(res);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  handleUploadChannelThumbnail(files: FileList) {
    const file = files[0];
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.channel.image = e.target.result as string;
    };
  }

  doToggleVisible(e: MatSlideToggleChange) {
    this.channelForm.get('isVisible').setValue(e.checked);
  }

  protected _onDestroy = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

export class EditChannelDialogModel {
  constructor(public channel: Channel) {}
}
