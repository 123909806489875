<div mat-dialog-title>
  <div class="label">Channel Embed Code</div>
</div>

<div mat-dialog-content>
  <div class="sub-label">Use this iFrame code to embed this channel on your website.</div>
  <mat-form-field appearance="outline" class="embed w-100">
    <textarea
      [value]="embedCode"
      [rows]="textAreaRow"
      matInput
      placeholder="Please input embed code"
      readonly
      #inputTarget
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" [ngxClipboard]="inputTarget" (cbOnSuccess)="copied($event)">Copy Code</button>
</div>
