<div class="container mt-5">
  <div class="section-header">
    <div>
      <div class="modal-header" *ngIf="type == 'S3'">
        <img class="youtube-icon" src="assets/images/amazon-s3-full.png" alt="S3" />
        <h2>Buckets</h2>
      </div>
    </div>

    <div class="top-modal-actions">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="ms-2"
        mat-icon-button
        matTooltip="Import Videos"
        *ngIf="!viewImporter"
        (click)="viewForm()"
      >
        <mat-icon>cloud_upload</mat-icon>
      </button>

      <div *ngIf="viewImporter">
        <button
          mat-stroked-button
          color="primary"
          type="button"
          class="ms-2"
          (click)="handleImportSelectedVideos()"
          mat-icon-button
          [matBadge]="selectedVideos.length"
          matBadgePosition="before"
          [matBadgeHidden]="selectedVideos.length == 0"
          matTooltip="Import Videos"
          [disabled]="selectedVideos.length == 0"
        >
          <mat-icon>cloud_upload</mat-icon>
        </button>
        <button
          color="primary"
          type="button"
          class="ms-2 small-btn"
          mat-icon-button
          matTooltip="Import History"
          (click)="fetchS3ImportHistory('all')"
        >
          <mat-icon>history</mat-icon>
        </button>
      </div>
    </div>

    <div class="importer-section" *ngIf="viewImporter">
      <div *ngIf="type == 'S3'" class="">
        <div class="modal-actions" *ngIf="videos.length != 0">
          <div class="modal-action-btns">
            <div class="result-info">Total Results: {{ videos.length }}</div>
            <div class="action-btn" (click)="selectAll()" [class.disabled-btn]="videos.length == selectedVideos.length">
              Select All
            </div>
            <div class="action-btn unselect-all" *ngIf="selectedVideos.length != 0" (click)="selectedVideos = []">
              Unselect All
            </div>
          </div>

          <div class="pagination">
            <label>Page Size:</label>
            <mat-radio-group aria-label="Page Size" color="primary" [(ngModel)]="selectedPageSize">
              <mat-radio-button [value]="10"><span style="font-size: 12px">10</span></mat-radio-button>
              <mat-radio-button [value]="20"><span style="font-size: 12px">20</span></mat-radio-button>
              <mat-radio-button [value]="40"><span style="font-size: 12px">50</span></mat-radio-button>
              <mat-radio-button [value]="80"><span style="font-size: 12px">100</span></mat-radio-button>
              <mat-radio-button [value]="100"><span style="font-size: 12px">200</span></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div
        class="modal"
        id="loadingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loadingModalLabel"
        aria-hidden="true"
        *ngIf="isFetching"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content bg-transparent border-0">
            <div class="modal-body text-center" style="width: 75%; margin: auto">
              <img
                src="https://i.pinimg.com/originals/47/af/82/47af824852bb6fb9f31e13d3e76a86be.gif"
                alt="Loading..."
                class="img-fluid"
              />

              <mat-progress-bar mode="indeterminate" style="margin: 10px 0px"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div class="error-view" *ngIf="hasError">
        <h3>No Videos Found</h3>
        <p>Please search again and ensure that the YouTube content URL is correct.</p>
      </div>
    </div>
  </div>
  <div class="import-history-section" *ngIf="viewImporter">
    <form [formGroup]="s3Form" (ngSubmit)="onSubmit()" class="s3-form">
      <div class="form-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>AWS Access Key ID</mat-label>
          <input matInput formControlName="aws_access_key_id" required />
          <mat-error *ngIf="s3Form.get('aws_access_key_id').hasError('required')">
            AWS Access Key ID is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>AWS Secret Access Key</mat-label>
          <input matInput formControlName="aws_secret_access_key" type="password" required />
          <mat-error *ngIf="s3Form.get('aws_secret_access_key').hasError('required')">
            AWS Secret Access Key is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Bucket Name</mat-label>
          <input matInput formControlName="aws_s3_bucket_name" required />
          <mat-error *ngIf="s3Form.get('aws_s3_bucket_name').hasError('required')"> Bucket Name is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Region Name</mat-label>
          <input matInput formControlName="region_name" required />
          <mat-error *ngIf="s3Form.get('region_name').hasError('required')"> Region Name is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Endpoint</mat-label>
          <input matInput formControlName="aws_endpoint" required />
          <mat-error *ngIf="s3Form.get('aws_endpoint').hasError('required')"> Endpoint is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Protocol</mat-label>
          <input matInput formControlName="aws_protocol" required />
          <mat-error *ngIf="s3Form.get('aws_protocol').hasError('required')"> Protocol is required </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit" [disabled]="s3Form.invalid" class="submit-btn">
          Submit
        </button>
      </div>
    </form>
  </div>

  <div class="import-history-section" *ngIf="!viewImporter">
    <div style="display: flex; align-items: center; gap: 10px; justify-content: space-between">
      <div style="display: flex; align-items: center; gap: 10px">
        <h2>Import History</h2>
        <button [disabled]="isFetchingImportHistory" mat-button (click)="fetchS3ImportHistory()">
          <mat-icon>refresh</mat-icon> Refresh
        </button>
      </div>

      <div class="search-container-layout">
        <button
          mat-flat-button
          color="primary"
          [matBadge]="selectedItemsToReImport.length"
          matBadgePosition="before"
          [matBadgeHidden]="selectedItemsToReImport.length == 0"
          [disabled]="selectedItemsToReImport.length == 0"
        >
          Re-import
        </button>
        <button mat-flat-button [matMenuTriggerFor]="filterMenu">
          <mat-icon matSuffix class="cursor-pointer">filter_list</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu">
          <button mat-menu-item (click)="onFilterOptionSelected('all')">ALL</button>
          <button mat-menu-item (click)="onFilterOptionSelected('uploaded')">UPLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('failed')">FAILED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('in_progress')">IN PROGRESS</button>
          <button mat-menu-item (click)="onFilterOptionSelected('downloaded')">DOWNLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('pending')">PENDING</button>
        </mat-menu>
        <form>
          <mat-form-field appearance="outline" class="search-input">
            <input
              matInput
              [(ngModel)]="query_params.search"
              name="search"
              placeholder="Input your search"
              (ngModelChange)="handleSearchVideo($event)"
            />
            <mat-icon matSuffix class="cursor-pointer">search</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>

    <br />
    <div *ngIf="isFetchingImportHistory" class="loading-view">
      <mat-spinner color="primary" diameter="40"> </mat-spinner>
    </div>

    <div *ngIf="hasError" class="error-view">
      <h3>Something went wrong.</h3>
      <p>We were unable to retrieve your import history.</p>
    </div>

    <div *ngIf="!isFetchingImportHistory && !hasError">
      <div *ngIf="importHistory.length != 0">
        <div class="import-list">
          <div class="select-all">
            <mat-checkbox color="primary" [(ngModel)]="selectAllReImport" (change)="toggleSelectAll($event.checked)">
              Select All
            </mat-checkbox>
          </div>
          <div *ngFor="let item of importHistory" style="display: flex; gap: 10px; align-items: center">
            <mat-checkbox color="primary" [(ngModel)]="item.selected" (change)="updateSelectedCount()"></mat-checkbox>
            <div class="import-item">
              <img [src]="item.thumbnail || '/assets/images/no-image.png'" alt="Thumbnail" class="item-thumbnail" />
              <div class="import-details">
                <div class="import-title">
                  <span class="import-title">{{ item.title }}</span>
                  <div class="import-info">
                    <div class="import-duration" *ngIf="type != 'Dropbox'">{{ item.duration }}</div>
                    <div class="date-imported">{{ item.date_imported | date: 'short' }}</div>
                  </div>
                </div>
                <div class="import-actions">
                  <span
                    class="item-status"
                    [ngClass]="{
                      pending: item.status === 'pending',
                      in_progress: item.status === 'in_progress',
                      downloaded: item.status === 'downloading',
                      uploaded: item.status === 'uploaded',
                      failed: item.status === 'failed'
                    }"
                    >{{ getStatus(item.status) }}</span
                  >
                  <button mat-icon-button matTooltip="More Actions" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item><mat-icon>refresh</mat-icon>Retry</button>
                    <button mat-menu-item><mat-icon>delete</mat-icon>Delete</button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-paginator
          [length]="importHistoryTotalItems"
          [pageSize]="query_params.page_size"
          [pageSizeOptions]="[10, 20, 40, 100]"
          [pageIndex]="query_params.page - 1"
          aria-label="Select page"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>

      <div class="empty-view error-view" *ngIf="importHistory.length == 0">
        <h3>No Data Found.</h3>
        <p>Please try searching again or ensure your import history is not empty.</p>
      </div>
    </div>
  </div>
</div>
