import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
// @ts-ignore
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { isMobile } from '@app/helpers/render';
import { ChannelService } from '@app/shared/services';
import { environment } from '@env/environment';

@Component({
  selector: 'app-stream-play',
  templateUrl: './stream-play.component.html',
  styleUrls: ['./stream-play.component.scss'],
})
export class StreamPlayComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  streamId: string;
  @Input()
  playType: any;
  @Input()
  token: any;
  @Input()
  pAutoplay = true;
  @Input()
  mute = true;
  @Input()
  targetLatency = 3;
  @Input()
  is360 = false;
  @Input()
  playOrder: any;
  @Input()
  subscriberId: any;
  @Input()
  subscriberCode: any;
  @Output()
  setStreamStatus = new EventEmitter<boolean>();
  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;
  @ViewChild('placeHolder') placeHolder: ElementRef<HTMLDivElement>;
  @ViewChild('unMuteButton') unMuteButton: ElementRef<HTMLButtonElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;
  autoPlay = true;
  hlsExtension = 'm3u8';
  dashExtension = 'mpd';
  iceConnected = false;
  webRTCAdaptor: any = null;
  streamsFolder = 'streams';
  streamUrl: string;
  socketUrl: string;
  player: any;
  playerUrl: any;
  isUseScaleEngine: boolean;
  streamHlsUrl: string;

  constructor(private renderer: Renderer2, private channelService: ChannelService) {
    // this.streamUrl = environment.streamUrl;
    // this.socketUrl = environment.antSocketUrl;
    // this.playerUrl = environment.playerUrl;
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.setStreamStatus.emit(false);
      this.player.dispose();
    }
  }

  ngOnChanges({ streamId }: SimpleChanges): void {
    if (streamId.currentValue !== streamId.previousValue) {
      console.log('streamId', streamId.currentValue);
      console.log('streamId previousValue', streamId.previousValue);
      this.main();
    }
  }

  ngOnInit(): void {
    console.log('ngOnInit');
    this.channelService.getPlayerUrl().subscribe((playerUrl) => {
      this.playerUrl = playerUrl;
      console.log('Updated playerUrl', this.playerUrl);
    });
    this.channelService.getStreamUrl().subscribe((streamUrl) => {
      this.streamUrl = streamUrl;
      console.log('Updated streamUrl', this.streamUrl);
    });
    this.channelService.getAntSocketUrl().subscribe((antSocketUrl) => {
      this.socketUrl = antSocketUrl;
      console.log('Updated antSocketUrl', this.socketUrl);
    });

    this.loadScripts();
    if (!this.playType || this.playType === 'mp4,webm') {
      this.playType = ['mp4', 'webm'];
    } else if (this.playType === 'webm,mp4') {
      this.playType = ['webm', 'mp4'];
    } else if (this.playType === 'mp4') {
      this.playType = ['mp4'];
    } else if (this.playType === 'webm') {
      this.playType = ['webm'];
    } else if (this.playType === 'mov') {
      this.playType = ['mov'];
    }

    if (!this.pAutoplay || isMobile()) {
      this.autoPlay = false;
    }

    this.initializePlayer = this.initializePlayer.bind(this);
    this.playWebRTCVideo = this.playWebRTCVideo.bind(this);
    this.changeVideoMuteStatus = this.changeVideoMuteStatus.bind(this);
    this.webrtcNoStreamCallback = this.webrtcNoStreamCallback.bind(this);
    this.hlsNoStreamCallback = this.hlsNoStreamCallback.bind(this);
  }

  ngAfterViewInit(): void {
    this.main();
  }

  async loadScripts() {
    if (!this.playOrder) {
      this.playOrder = ['webrtc', 'hls'];
    } else {
      this.playOrder = this.playOrder.split(',');
    }

    if (this.playOrder.includes('hls') || this.playOrder.includes('vod')) {
      this.loadAssets(this.renderer, 'assets/js/external/video.js', 'text/javascript');
      this.loadAssets(this.renderer, 'assets/css/external/video-js.css', 'stylesheet');
    }
  }

  // This function creates a-scene elements only if is360 parameter is true.
  // This makes a-scene doesn't run in the background if it is not needed.
  createVrVideo() {
    const vrScene = document.createElement('a-scene');
    vrScene.style.display = 'none';
    vrScene.innerHTML =
      '<a-videosphere src="#remoteVideo" rotation="0 180 0" style="background-color: antiquewhite"></a-videosphere>';
    const remoteVideoNode = this.remoteVideo.nativeElement;
    remoteVideoNode.parentNode.insertBefore(vrScene, remoteVideoNode.nextSibling);
  }

  // This function mutes or unmutes the 360 video stream.
  // This is necessary because the browser doesn't allow videos to be played automatically and unmuted by default.
  // Exceptions are possible: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
  changeVideoMuteStatus() {
    // Checks the current "mute" status of the video
    if (this.remoteVideo.nativeElement.muted) {
      // Unmute the video and change the button's text
      this.remoteVideo.nativeElement.muted = false;
      this.unMuteButton.nativeElement.innerText = 'Mute';
    } else {
      this.remoteVideo.nativeElement.muted = true;
      this.unMuteButton.nativeElement.innerText = 'Unmute';
    }
  }

  genericCallback(currentTech: any) {
    this.placeHolder.nativeElement.innerHTML = 'Stream will start playing automatically<br/>when it is live';
    setTimeout(() => {
      let index = this.playOrder.indexOf(currentTech);

      if (currentTech === 'webrtc') {
        if (!this.iceConnected) {
          if (index === -1 || index === this.playOrder.length - 1) {
            index = 0;
          } else {
            index++;
          }
        }
      } else {
        if (index === -1 || index === this.playOrder.length - 1) {
          index = 0;
        } else {
          index++;
        }
      }

      const tech = this.playOrder[index];

      if (tech === 'webrtc') {
        // It means there is no HLS stream, so try to play WebRTC stream
        if (!this.webRTCAdaptor) {
          this.initializeWebRTCPlayer(
            this.streamId,
            this.token,
            this.subscriberId,
            this.subscriberCode,
            this.webrtcNoStreamCallback
          );
        } else {
          this.webRTCAdaptor.getStreamInfo(this.streamId);
        }
      } else if (tech === 'hls') {
        this.tryToPlay(
          this.streamId,
          this.token,
          this.hlsExtension,
          this.subscriberId,
          this.subscriberCode,
          this.hlsNoStreamCallback
        );
      }
    }, 3000);
  }

  webrtcNoStreamCallback() {
    /**
     * If HLS is in the play order then try to play HLS, if not wait for WebRTC stream
     * In some cases user may want to remove HLS from the order and force to play WebRTC only
     * in these cases player only waits for WebRTC streams
     */
    this.genericCallback('webrtc');
  }

  hlsNoStreamCallback() {
    this.genericCallback('hls');
  }

  setHLSElementsVisibility(show: any) {
    this.videoContainer.nativeElement.style.display = show === true ? 'block' : 'none';
  }

  hideWebRTCElements() {
    this.setWebRTCElementsVisibility(false);
    document.getElementById('play_button').style.display = 'none';
  }

  setWebRTCElementsVisibility(show: any) {
    this.remoteVideo.nativeElement.style.display = show === true ? 'block' : 'none';
  }

  setPlaceHolderVisibility(show: any) {
    this.placeHolder.nativeElement.style.display = show === true ? 'block' : 'none';
  }

  show360ElementsVisibility() {
    this.unMuteButton.nativeElement.style.display = 'block';
  }

  playWebRTCVideo() {
    this.setWebRTCElementsVisibility(true);

    if (this.is360) {
      this.unMuteButton.nativeElement.style.display = 'block';
    }

    if (this.mute) {
      this.remoteVideo.nativeElement.muted = true;
      this.unMuteButton.nativeElement.innerText = 'Unmute';
    } else {
      this.remoteVideo.nativeElement.muted = false;
      this.unMuteButton.nativeElement.innerText = 'Mute';
    }
    if (this.autoPlay) {
      this.remoteVideo.nativeElement
        .play()
        .then((value: any) => {
          // autoplay started
          document.getElementById('play_button').style.display = 'none';
        })
        .catch((error: any) => {
          document.getElementById('play_button').style.display = 'block';
          // console.log('User interaction needed to start playing');
        });
    }
  }

  initializePlayer(streamId: any, extension: any, token: any, subscriberId?: any, subscriberCode?: any) {
    this.hideWebRTCElements();
    this.startPlayer(streamId, extension, token, subscriberId, subscriberCode);
  }

  startPlayer(streamId: any, extension: any, token: any, subscriberId: any, subscriberCode: any) {
    let type;
    let liveStream = false;
    if (extension === 'mp4') {
      type = 'video/mp4';
      liveStream = false;
    } else if (extension === 'webm') {
      type = 'video/webm';
      liveStream = false;
    } else if (extension === 'mov') {
      type = 'video/mp4';
      alert('Browsers do not support to play mov format');
      liveStream = false;
    } else if (extension === 'avi') {
      type = 'video/mp4';
      alert('Browsers do not support to play avi format');
      liveStream = false;
    } else if (extension === 'm3u8') {
      type = 'application/x-mpegURL';
      liveStream = true;
    } else if (extension === 'mpd') {
      type = 'application/dash+xml';
      liveStream = true;
    } else {
      // console.log('Unknown extension: ' + extension);
      return;
    }

    let preview = streamId;
    if (streamId.endsWith('_adaptive')) {
      preview = streamId.substring(0, streamId.indexOf('_adaptive'));
    }

    this.player = this.remoteVideo.nativeElement;

    if (!this.is360) {
      // Get isUseScaleEngine's value state
      this.channelService.getIsUseScaleEngine().subscribe((isUseScaleEngine) => {
        this.isUseScaleEngine = isUseScaleEngine;
      });

      // If it's not dash, play with videojs
      if (extension !== this.dashExtension) {
        this.player = (window as any).videojs('video-player', {
          poster: 'previews/' + preview + '.png',
          liveui: liveStream,
          liveTracker: {
            trackingThreshold: 0,
          },
        });

        (window as any).videojs.Hls.xhr.beforeRequest = (options: any) => {
          options.uri = options.uri;
          return options;
        };
        if (this.playerUrl == 'EXTERNAL') {
          this.player.src({
            src: this.streamUrl,
            type,
            // withCredentials: true,
          });
        } else {
          // Get the stream hls url' value from state
          this.channelService.getStreamHlsUrl().subscribe((value) => {
            this.streamHlsUrl = value;
          });

          // if Scale engine is used, we have to append 'streamId' and 'playlist.m3u8' to a streamURL, otherwise streamUrl only.
          const streamUrl = this.isUseScaleEngine
            ? this.streamUrl + streamId + '/' + 'playlist.m3u8'
            : this.streamHlsUrl;

          this.player.src({
            src: streamUrl,
            type,
            // withCredentials: true,
          });
        }

        this.player.poster('previews/' + preview + '.png');

        if (this.mute) {
          this.player.muted(true);
        } else {
          this.player.muted(false);
        }

        if (this.autoPlay) {
          this.player.ready(() => {
            this.player.play();
          });
        }
      }
    }

    if (!this.is360) {
      this.setHLSElementsVisibility(true);
      this.setWebRTCElementsVisibility(false);
      if (typeof this.player.ready !== 'undefined') {
        const self = this;
        this.player.ready(() => {
          self.setStreamStatus.emit(true);
          this.player.on('ended', () => {
            self.setStreamStatus.emit(false);
            self.hideWebRTCElements();
            self.setHLSElementsVisibility(false);
            self.setPlaceHolderVisibility(true);
            this.tryToPlay(streamId, token, extension, subscriberId, subscriberCode, self.hlsNoStreamCallback);
          });
        });
      }
    } else {
      // console.log('player ready is not available. List playing may not be continous');
    }
    this.setPlaceHolderVisibility(false);
  }

  initializeWebRTCPlayer(streamId: any, token: any, subscriberId: any, subscriberCode: any, noStreamCallback: any) {
    if (this.is360) {
      this.show360ElementsVisibility();
    }
    this.setHLSElementsVisibility(false);

    const pc_config = {
      iceServers: [
        {
          urls: 'stun:stun1.l.google.com:19302',
        },
      ],
    };

    const sdpConstraints = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    };
    const mediaConstraints = {
      video: false,
      audio: false,
    };

    const websocketURL = this.socketUrl;

    this.iceConnected = false;
    setTimeout(() => {
      // webRTCAdaptor is a global variable
      this.webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        remoteVideoId: 'remoteVideo',
        isPlayMode: true,
        debug: true,
        callback: (info: any, description: any) => {
          if (info === 'initialized') {
            // //console.log('initialized');
            this.iceConnected = false;
            this.webRTCAdaptor.getStreamInfo(streamId);
          } else if (info === 'streamInformation') {
            // //console.log('stream information');
            this.webRTCAdaptor.play(streamId, token, '', [], subscriberId, subscriberCode);
          } else if (info === 'play_started') {
            // joined the stream
            this.setPlaceHolderVisibility(false);
            this.setHLSElementsVisibility(false);
            this.playWebRTCVideo();
            this.setStreamStatus.emit(true);
          } else if (info === 'play_finished') {
            // leaved the stream
            this.setHLSElementsVisibility(false);
            this.hideWebRTCElements();
            this.setPlaceHolderVisibility(true);
            this.setStreamStatus.emit(false);
            // if play_finished event is received, it has two meanings
            // 1. stream is really finished
            // 2. ice connection cannot be established and server reports play_finished event
            // check that publish may start again
            if (this.iceConnected) {
              // webrtc connection was successful and try to play again with webrtc
              setTimeout(() => {
                this.webRTCAdaptor.getStreamInfo(streamId);
              }, 3000);
            } else {
              // webrtc connection was not succesfull, switch the next play type(playOrder) if available
              if (typeof noStreamCallback !== 'undefined') {
                noStreamCallback();
              }
            }
          } else if (info === 'closed') {
            // //console.log("Connection closed");
            if (typeof description !== 'undefined') {
              // console.log('Connecton closed: ' + JSON.stringify(description));
            }
          } else if (info === 'bitrateMeasurement') {
            if (!this.remoteVideo.nativeElement.paused) {
              document.getElementById('play_button').style.display = 'none';
            }

            // console.debug(description);
            if (description.audioBitrate + description.videoBitrate > description.targetBitrate) {
              document.getElementById('networkWarning').style.display = 'block';
              setTimeout(() => {
                document.getElementById('networkWarning').style.display = 'none';
              }, 3000);
            }
          } else if (info === 'ice_connection_state_changed') {
            // console.debug('ice connection state changed to ' + description.state);
            if (description.state === 'connected' || description.state === 'completed') {
              // it means the ice connection has been established
              this.iceConnected = true;
            }
          } else if (info === 'resolutionChangeInfo') {
            // console.log('Resolution is changed to ' + description.streamHeight);
            const getVideo = this.remoteVideo.nativeElement as any;
            const overlay = document.getElementById('video-overlay') as any;
            getVideo.pause();
            overlay.style.display = 'block';
            setTimeout(() => {
              getVideo.play();
              overlay.style.display = 'none';
            }, 2000);
          }
        },
        callbackError: (error: any) => {
          // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

          // console.log('error callback: ' + JSON.stringify(error));

          if (error === 'no_stream_exist') {
            if (typeof noStreamCallback !== 'undefined') {
              noStreamCallback();
            }
          }
          if (error === 'notSetRemoteDescription') {
            /*
             * If getting codec incompatible or remote description error, it will redirect HLS player.
             */
            this.tryToPlay(
              this.streamId,
              this.token,
              this.hlsExtension,
              this.subscriberId,
              this.subscriberCode,
              this.hlsNoStreamCallback
            );
          }
        },
      });
    }, 1000);
  }

  main() {
    if (typeof this.streamId !== 'undefined') {
      if (this.streamId.startsWith(this.streamsFolder)) {
        /*
         * If streamId starts with streams, it's hls or mp4 file to be played
         */
        const lastIndexOfDot = this.streamId.lastIndexOf('.');
        const streamPath = this.streamId.substring(this.streamsFolder.length + 1, lastIndexOfDot);
        const extension = this.streamId.substring(lastIndexOfDot + 1);
        this.initializePlayer(this.streamId, 'm3u8', this.token);
      } else {
        /*
         * Check that which one is in the first order
         */
        if (this.playOrder[0] === 'webrtc') {
          this.initializeWebRTCPlayer(
            this.streamId,
            this.token,
            this.subscriberId,
            this.subscriberCode,
            this.webrtcNoStreamCallback
          );
        } else if (this.playOrder[0] === 'hls') {
          this.tryToPlay(
            this.streamId,
            this.token,
            this.hlsExtension,
            this.subscriberId,
            this.subscriberCode,
            this.hlsNoStreamCallback
          );
        }
        //  else {
        //   // alert(
        //   //   'Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls'
        //   // );
        // }
      }
    } else if (typeof this.streamId == 'undefined' && this.playerUrl == 'EXTERNAL') {
      if (this.playOrder[0] === 'webrtc') {
        this.initializeWebRTCPlayer(
          this.streamId,
          this.token,
          this.subscriberId,
          this.subscriberCode,
          this.webrtcNoStreamCallback
        );
      } else if (this.playOrder[0] === 'hls') {
        this.tryToPlay(
          'undefined',
          this.token,
          this.hlsExtension,
          this.subscriberId,
          this.subscriberCode,
          this.hlsNoStreamCallback
        );
      }
    } else {
      this.hideWebRTCElements();
      this.setHLSElementsVisibility(false);
      this.setPlaceHolderVisibility(true);
    }
  }

  tryToPlay(
    name: string,
    token: string,
    type: string,
    subscriberId: string,
    subscriberCode: string,
    noStreamCallback: any
  ) {
    this.initializePlayer(name, type, token, subscriberId, subscriberCode);
    // if (this.playerUrl == 'EXTERNAL') {
    //   setTimeout(() => {
    //     this.initializePlayer(name, type, token, subscriberId, subscriberCode);
    //   }, 5000);
    // } else {
    //   fetch(`${this.playerUrl}play.html?name=${name}`, { method: 'HEAD' })
    //     .then((response) => {
    //       if (response.status === 200 && this.streamId) {
    //         this.initializePlayer(name, type, token, subscriberId, subscriberCode);
    //       } else {
    //         return;
    //       }
    //     })
    //     .catch((err) => {
    //       // console.log('Error: ' + err);
    //     });
    // }
  }

  loadAssets(renderer: Renderer2, src: string, type: string) {
    if (type === 'text/javascript') {
      const script = renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      renderer.appendChild(document.body, script);
      return script;
    } else {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = src;
      renderer.appendChild(document.head, link);
      return link;
    }
  }
}
