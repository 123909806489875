<div class="container mt-5">
  <div class="section-header">
    <div>
      <div class="modal-header" *ngIf="type == 'YouTube'">
        <img class="youtube-icon" src="assets/images/youtube2.png" alt="Youtube" />
        <h2>YouTube</h2>
      </div>
      <div class="modal-header" *ngIf="type == 'Vimeo'">
        <img class="youtube-icon" src="assets/images/vimeo-full.png" alt="Vimeo" style="width: 80px" />
      </div>
      <div class="modal-header" *ngIf="type == 'Dropbox'">
        <img class="youtube-icon" src="assets/images/dropbox-full.png" alt="Youtube" style="width: 100px" />
      </div>
    </div>

    <div class="top-modal-actions">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="ms-2"
        mat-icon-button
        matTooltip="Import Videos"
        *ngIf="!viewImporter"
        (click)="viewImporterSection()"
      >
        <mat-icon>cloud_upload</mat-icon>
      </button>

      <div *ngIf="viewImporter">
        <button
          mat-stroked-button
          color="primary"
          type="button"
          class="ms-2"
          (click)="handleImportSelectedVideos()"
          mat-icon-button
          [matBadge]="selectedVideos.length"
          matBadgePosition="before"
          [matBadgeHidden]="selectedVideos.length == 0"
          matTooltip="Import Videos"
          [disabled]="selectedVideos.length == 0"
        >
          <mat-icon>cloud_upload</mat-icon>
        </button>
        <button
          color="primary"
          type="button"
          class="ms-2 small-btn"
          (click)="fetchImportHistory('all')"
          mat-icon-button
          matTooltip="Import History"
        >
          <mat-icon>history</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="importer-section" *ngIf="viewImporter">
    <div *ngIf="type == 'YouTube'" class="youtube-importer-main">
      <form class="bg-light p-3 mb-3" style="margin-top: 10px">
        <div class="input-wrapper">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Input video content url (video, channel, playlist)</mat-label>
            <input matInput [(ngModel)]="contentUrl" name="contentUrl" />
          </mat-form-field>
          <div style="margin-top: 5px">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="ms-2"
              [disabled]="!contentUrl || isFetching"
              (click)="fetchYoutubeVideos()"
            >
              Search
            </button>
          </div>
        </div>
      </form>

      <!-- Updated Notice Warning -->
      <div
        class="notice"
        style="
          border: 1px solid #ffcc00;
          background-color: #fff8e1;
          border-radius: 5px;
          padding: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 25px;
        "
      >
        <mat-icon style="color: #ff9800; margin-right: 10px">warning</mat-icon>
        <div>
          <h4 style="margin: 0; color: #e65100">Attention!</h4>
          <p style="margin: 5px 0; color: #333">
            You can import a maximum of 20 videos at a time. Once an import is in progress, wait for it to complete
            before starting another. Imports may occasionally fail due to YouTube policies;
            <strong>If this happens, please retry the operation.</strong>
          </p>
        </div>
      </div>

      <div class="modal-actions" *ngIf="videos.length != 0">
        <div class="modal-action-btns">
          <div class="result-info">Total Results: {{ videos.length }}</div>
          <div class="action-btn" (click)="selectAll()" [class.disabled-btn]="videos.length == selectedVideos.length">
            Select All
          </div>
          <div class="action-btn unselect-all" *ngIf="selectedVideos.length != 0" (click)="selectedVideos = []">
            Unselect All
          </div>
        </div>

        <div class="pagination">
          <label>Page Size:</label>
          <mat-radio-group aria-label="Page Size" color="primary" [(ngModel)]="selectedPageSize">
            <mat-radio-button [value]="10"><span style="font-size: 12px">10</span></mat-radio-button>
            <mat-radio-button [value]="20"><span style="font-size: 12px">20</span></mat-radio-button>
            <mat-radio-button [value]="40"><span style="font-size: 12px">50</span></mat-radio-button>
            <mat-radio-button [value]="80"><span style="font-size: 12px">100</span></mat-radio-button>
            <mat-radio-button [value]="100"><span style="font-size: 12px">200</span></mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div *ngIf="type == 'Vimeo'" class="youtube-importer-main">
      <form class="bg-light p-3 mb-3" style="margin: 10px 0">
        <div class="input-wrapper">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Search by name</mat-label>
            <input matInput [(ngModel)]="name" name="name" (keyup.enter)="fetchVimeoVideos()" />
          </mat-form-field>
          <div
            style="
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              align-items: baseline;
            "
          >
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="ms-2"
              [disabled]="isFetching"
              (click)="fetchVimeoVideos()"
            >
              Fetch Videos or Search by Name
            </button>
          </div>
        </div>
      </form>
      <div class="modal-actions" *ngIf="videos.length != 0">
        <div class="modal-action-btns">
          <div class="result-info">Total Results: {{ videos.length }}</div>
          <div class="action-btn" (click)="selectAll()" [class.disabled-btn]="videos.length == selectedVideos.length">
            Select All
          </div>
          <div class="action-btn unselect-all" *ngIf="selectedVideos.length != 0" (click)="selectedVideos = []">
            Unselect All
          </div>
        </div>

        <div class="pagination">
          <div class="pagination-section">
            <div>Page</div>
            <mat-form-field appearance="outline">
              <label for=""></label>
              <mat-select [(ngModel)]="selectedPage">
                <mat-option *ngFor="let page of availablePages" [value]="page">
                  {{ page }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="pagination-section">
            <div>Page Size:</div>
            <mat-form-field appearance="outline">
              <label for=""></label>
              <mat-select [(ngModel)]="selectedPageSize">
                <mat-option *ngFor="let pageSize of [10, 20, 40, 80, 100]" [value]="pageSize">
                  {{ pageSize }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="type == 'Dropbox'" class="youtube-importer-main">
      <form class="bg-light p-3 mb-3" style="margin-top: 10px">
        <div class="input-wrapper">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Input dropbox folder url</mat-label>
            <input matInput [(ngModel)]="contentUrl" name="contentUrl" />
          </mat-form-field>
          <div style="margin-top: 5px">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="ms-2"
              [disabled]="!contentUrl || isFetching"
              (click)="fetchDropboxVideos()"
            >
              Search
            </button>
          </div>
        </div>
      </form>
      <div class="modal-actions" *ngIf="videos.length != 0">
        <div class="modal-action-btns">
          <div class="result-info">Total Results: {{ videos.length }}</div>
          <div class="action-btn" (click)="selectAll()" [class.disabled-btn]="videos.length == selectedVideos.length">
            Select All
          </div>
          <div class="action-btn unselect-all" *ngIf="selectedVideos.length != 0" (click)="selectedVideos = []">
            Unselect All
          </div>
        </div>

        <div class="pagination">
          <label>Page Size:</label>
          <mat-radio-group aria-label="Page Size" color="primary" [(ngModel)]="selectedPageSize">
            <mat-radio-button [value]="10"><span style="font-size: 12px">10</span></mat-radio-button>
            <mat-radio-button [value]="20"><span style="font-size: 12px">20</span></mat-radio-button>
            <mat-radio-button [value]="40"><span style="font-size: 12px">50</span></mat-radio-button>
            <mat-radio-button [value]="80"><span style="font-size: 12px">100</span></mat-radio-button>
            <mat-radio-button [value]="100"><span style="font-size: 12px">200</span></mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="importer-main-items-section">
      <div class="video-grid" *ngIf="!isFetching && !hasError">
        <div
          *ngFor="let video of videos"
          class="video-card"
          [class.is-selected]="this.selectedVideos.includes(video.url) || this.selectedVideos.includes(video)"
        >
          <div class="video-item" (click)="selectVideo(video)">
            <div class="thumbnail">
              <img [src]="video.thumbnail" [alt]="video.title" />
              <span class="duration">{{ video.duration }}</span>
              <span class="selected-indicator" *ngIf="this.selectedVideos.includes(video.url)"> selected </span>
              <span class="selected-indicator" *ngIf="this.selectedVideos.includes(video)"> selected </span>
            </div>
            <div class="video-info">
              <h3 class="video-title">{{ video.title }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal"
        id="loadingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loadingModalLabel"
        aria-hidden="true"
        *ngIf="isFetching"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content bg-transparent border-0">
            <div class="modal-body text-center" style="width: 75%; margin: auto">
              <img
                src="https://i.pinimg.com/originals/47/af/82/47af824852bb6fb9f31e13d3e76a86be.gif"
                alt="Loading..."
                class="img-fluid"
              />

              <mat-progress-bar mode="indeterminate" style="margin: 10px 0px"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div class="error-view" *ngIf="hasError">
        <h3>No Videos Found</h3>
        <p>Please search again and ensure that the YouTube content URL is correct.</p>
      </div>
    </div>
  </div>

  <div class="import-history-section" *ngIf="!viewImporter">
    <div style="display: flex; align-items: center; gap: 10px; justify-content: space-between">
      <div style="display: flex; align-items: center; gap: 10px">
        <h2>Import History</h2>
        <button [disabled]="isFetchingImportHistory" mat-button (click)="fetchImportHistory()">
          <mat-icon>refresh</mat-icon> Refresh
        </button>
      </div>

      <div class="search-container-layout">
        <button
          mat-flat-button
          color="primary"
          [matBadge]="selectedItemsToReImport.length"
          matBadgePosition="before"
          [matBadgeHidden]="selectedItemsToReImport.length == 0"
          [disabled]="selectedItemsToReImport.length == 0"
          (click)="handleReImport()"
        >
          Re-import
        </button>
        <button mat-flat-button [matMenuTriggerFor]="filterMenu">
          <mat-icon matSuffix class="cursor-pointer">filter_list</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu">
          <button mat-menu-item (click)="onFilterOptionSelected('all')">ALL</button>
          <button mat-menu-item (click)="onFilterOptionSelected('uploaded')">UPLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('failed')">FAILED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('in_progress')">IN PROGRESS</button>
          <button mat-menu-item (click)="onFilterOptionSelected('downloaded')">DOWNLOADED</button>
          <button mat-menu-item (click)="onFilterOptionSelected('pending')">PENDING</button>
        </mat-menu>
        <form>
          <mat-form-field appearance="outline" class="search-input">
            <input
              matInput
              [(ngModel)]="query_params.search"
              name="search"
              placeholder="Input your search"
              (ngModelChange)="handleSearchVideo($event)"
            />
            <mat-icon matSuffix class="cursor-pointer">search</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>

    <br />
    <div *ngIf="isFetchingImportHistory" class="loading-view">
      <mat-spinner color="primary" diameter="40"> </mat-spinner>
    </div>

    <div *ngIf="hasError" class="error-view">
      <h3>Something went wrong.</h3>
      <p>We were unable to retrieve your import history.</p>
    </div>

    <div *ngIf="!isFetchingImportHistory && !hasError">
      <div *ngIf="importHistory.length != 0">
        <div class="import-list">
          <div class="select-all">
            <mat-checkbox color="primary" [(ngModel)]="selectAllReImport" (change)="toggleSelectAll($event.checked)">
              Select All
            </mat-checkbox>
          </div>
          <div *ngFor="let item of importHistory" style="display: flex; gap: 10px; align-items: center">
            <mat-checkbox color="primary" [(ngModel)]="item.selected" (change)="updateSelectedCount()"></mat-checkbox>
            <div class="import-item">
              <img [src]="item.thumbnail || '/assets/images/no-image.png'" alt="Thumbnail" class="item-thumbnail" />
              <div class="import-details">
                <div class="import-title">
                  <span class="import-title">{{ item.title }}</span>
                  <div class="import-info">
                    <div class="import-duration" *ngIf="type != 'Dropbox'">{{ item.duration }}</div>
                    <div class="date-imported">{{ item.date_imported | date: 'short' }}</div>
                  </div>
                </div>
                <div class="import-actions">
                  <span
                    class="item-status"
                    [ngClass]="{
                      pending: item.status === 'pending',
                      in_progress: item.status === 'in_progress',
                      downloaded: item.status === 'downloading',
                      uploaded: item.status === 'uploaded',
                      failed: item.status === 'failed'
                    }"
                    >{{ getStatus(item.status) }}</span
                  >
                  <button mat-icon-button matTooltip="More Actions" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="retryImportItem(item.uuid)">
                      <mat-icon>refresh</mat-icon>Retry
                    </button>
                    <button mat-menu-item (click)="deleteImportHistoryItem(item.uuid)">
                      <mat-icon>delete</mat-icon>Delete
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-paginator
          [length]="importHistoryTotalItems"
          [pageSize]="query_params.page_size"
          [pageSizeOptions]="[10, 20, 40, 100]"
          [pageIndex]="query_params.page - 1"
          aria-label="Select page"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>

      <div class="empty-view error-view" *ngIf="importHistory.length == 0">
        <h3>No Data Found.</h3>
        <p>Please try searching again or ensure your import history is not empty.</p>
      </div>
    </div>
  </div>
</div>
